import { cn } from '@/lib/cn'
import '@/styles/globals.css'
// import '@fontsource/inter/variable.css'
// import { GeistMono, GeistSans } from 'geist/font'
import { AppProps } from 'next/app'
import { Inter as FontSans } from 'next/font/google'
import localFont from 'next/font/local'

const fontSans = FontSans({
  subsets: ['latin'],
  variable: '--font-sans',
})

const fontHeading = localFont({
  src: '../public/fonts/CalSans-SemiBold.woff2',
  variable: '--font-heading',
})

export default function App({ Component, pageProps }: AppProps) {
  return (
    <div
      className={cn(
        'min-h-screen bg-background font-sans antialiased',
        fontSans.variable,
        fontHeading.variable
        // GeistSans.variable,
        // GeistMono.variable
      )}
    >
      {/* <ThemeProvider attribute="class" defaultTheme="system" enableSystem> */}

      <Component {...pageProps} />
      {/* </ThemeProvider> */}
    </div>
  )
}

// https://github.com/axiomhq/next-axiom?utm_source=twitter&utm_medium=post#web-vitals
// export { reportWebVitals } from 'next-axiom'
